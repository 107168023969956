import { useState, useCallback, useRef, ChangeEvent } from 'react';
import {
  VStack,
  Input,
  Button,
  useToast
} from '@chakra-ui/react';
import { calculateProfit } from '../services/api';
import { CalculationResult } from '../types/calculator';
import { useUrlParams } from '../hooks/useUrlParams';

interface CalculatorProps {
  onResult: (result: CalculationResult) => void;
}

export const Calculator = ({ onResult }: CalculatorProps) => {
  const [usdAmount, setUsdAmount] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const calculationInProgress = useRef(false);
  const toast = useToast();
  const isInitialMount = useRef(true);

  const handleCalculation = useCallback(async (amount: number) => {
    if (calculationInProgress.current) return;
    
    try {
      calculationInProgress.current = true;
      setIsLoading(true);
      
      const data = await calculateProfit(amount);
      onResult(data);
    } catch (error) {
      toast({
        title: '错误',
        description: '计算失败，请重试',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
      calculationInProgress.current = false;
    }
  }, [onResult, toast]);

  const { updateUrlParams } = useUrlParams((amount) => {
    setUsdAmount(amount.toString());
    if (isInitialMount.current) {
      handleCalculation(amount);
      isInitialMount.current = false;
    }
  });

  const handleCalculate = async () => {
    if (!usdAmount || isNaN(parseFloat(usdAmount))) {
      toast({
        title: '输入错误',
        description: '请输入有效的 USD 金额',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const amount = parseFloat(usdAmount);
    await handleCalculation(amount);
    updateUrlParams(amount);
  };

  return (
    <VStack spacing={4}>
      <Input
        placeholder="输入 USD 金额"
        value={usdAmount}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setUsdAmount(e.target.value)}
        type="number"
        size="lg"
        disabled={isLoading}
      />
      
      <Button
        colorScheme="blue"
        onClick={handleCalculate}
        isLoading={isLoading}
        width="100%"
        disabled={!usdAmount || isLoading}
      >
        计算
      </Button>
    </VStack>
  );
};