import { useState } from 'react';
import { Box, Container, Heading } from '@chakra-ui/react';
import { Calculator } from './components/Calculator';
import { ResultDisplay } from './components/ResultDisplay';
import { CalculationResult } from './types/calculator';

export const App = () => {
  const [result, setResult] = useState<CalculationResult | null>(null);

  return (
    <Container maxW="container.sm" py={8}>
      <Box mb={8}>
        <Heading textAlign="center">SOL Calculator</Heading>
      </Box>
      <Calculator onResult={setResult} />
      {result && <Box mt={8}><ResultDisplay result={result} /></Box>}
    </Container>
  );
};