import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

export const useUrlParams = (onAmountChange: (amount: number) => void) => {
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const amount = searchParams.get('amount');
    if (amount) {
      const numAmount = parseFloat(amount);
      if (!isNaN(numAmount)) {
        onAmountChange(numAmount);
      }
    }
  }, [searchParams, onAmountChange]);

  const updateUrlParams = (amount: number) => {
    setSearchParams({ amount: amount.toString() });
  };

  return { updateUrlParams };
};