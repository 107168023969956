import { INITIAL_SOL, API_ENDPOINTS } from '../utils/constants';
import { CalculationResult } from '../types/calculator';

export const calculateProfit = async (usdAmount: number): Promise<CalculationResult> => {
  try {
    const [jupiterResponse, exchangeResponse] = await Promise.all([
      fetch(API_ENDPOINTS.JUPITER).then(res => res.json()),
      fetch(API_ENDPOINTS.EXCHANGE_RATES).then(res => res.json())
    ]);

    const solPrice = parseFloat(jupiterResponse.data.So11111111111111111111111111111111111111112.price);
    const solAmount = usdAmount / solPrice;
    const cnyRate = exchangeResponse.value;
    const isDefaultRate = exchangeResponse.isDefault;
    const cnyAmount = usdAmount * cnyRate;

    const profitSol = solAmount - INITIAL_SOL;
    const profitUsd = profitSol * solPrice;
    const profitCny = profitUsd * cnyRate;

    return {
      currentValues: {
        sol: solAmount,
        usd: usdAmount,
        cny: cnyAmount
      },
      profit: {
        sol: profitSol,
        usd: profitUsd,
        cny: profitCny
      },
      initialSol: INITIAL_SOL,
      isDefaultRate
    };
  } catch (error) {
    throw new Error('Failed to calculate profit');
  }
};