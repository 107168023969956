import { Box, Text, VStack, HStack, Divider } from '@chakra-ui/react';
import { CalculationResult } from '../types/calculator';

interface ResultDisplayProps {
  result: CalculationResult;
}

export const ResultDisplay = ({ result }: ResultDisplayProps) => {
  const isProfitable = result.profit.usd >= 0;
  const formatNumber = (num: number, decimals = 2) => 
    new Intl.NumberFormat('zh-CN', { 
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals 
    }).format(num);

  function formatSol(value: number) {
    return value.toFixed(2)
  }

  return (
    <Box
      p={6}
      borderRadius="xl"
      boxShadow="xl"
      bg="white"
      position="relative"
      overflow="hidden"
      _before={{
        content: '""',
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        height: "5px",
        bgGradient: "linear(to-r, blue.400, purple.500)"
      }}
    >
      <VStack align="stretch" spacing={6}>
        <Box>
          <Text fontSize="lg" fontWeight="medium" color="gray.600" mb={4}>
            当前价值
          </Text>
          <VStack align="stretch" spacing={3}>
            <HStack justify="space-between">
              <Text color="gray.600">初始投资 SOL</Text>
              <Text fontWeight="bold">{formatSol(result.initialSol)}</Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="gray.600">当前 SOL 价值</Text>
              <Text fontWeight="bold">{formatSol(result.currentValues.sol)}</Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="gray.600">当前 USD 价值</Text>
              <Text fontWeight="bold">
                ${formatNumber(result.currentValues.usd)}
              </Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="gray.600">当前 CNY 价值</Text>
              <Text fontWeight="bold">¥{formatNumber(result.currentValues.cny)}</Text>
            </HStack>
          </VStack>
        </Box>

        <Divider />

        <Box>
          <Text fontSize="lg" fontWeight="medium" color="gray.600" mb={4}>
            净利润
          </Text>
          <VStack
            align="stretch"
            spacing={3}
            p={4}
            bg={isProfitable ? 'green.50' : 'red.50'}
            borderRadius="md"
          >
            <HStack justify="space-between">
              <Text color="gray.600">SOL 净利润</Text>
              <Text fontWeight="bold" color={isProfitable ? 'green.500' : 'red.500'}>
                {formatSol(result.profit.sol)}
              </Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="gray.600">USD 净利润</Text>
              <Text fontWeight="bold" color={isProfitable ? 'green.500' : 'red.500'}>
                ${formatNumber(result.profit.usd)}
              </Text>
            </HStack>
            <HStack justify="space-between">
              <Text color="gray.600">CNY 净利润</Text>
              <Text fontWeight="bold" color={isProfitable ? 'green.500' : 'red.500'}>
                ¥{formatNumber(result.profit.cny)}
              </Text>
            </HStack>
          </VStack>
        </Box>
      </VStack>
    </Box>
  );
};